<template>
  <div class="page">
    <div class="title">(D-Link路由器)如何将Wi-Fi的SSID拆成2.4G和5G两个频段?</div>
    <div class="line"></div>
    <p>第一步、点击“设置”,选择“无线”选项;</p>
    <p><img src="@/assets/document/DLinkRouter/1.png" alt="" /></p>
    <p>
      第二步、找到“2.4GHz”,将下方的“Wi-Fi名称(SSID)”的名称改为“XXX-2.4G”,找到“5GHz”,将下方的“Wi-Fi名称(SSID)”的名称改为“XXX-5G”保存设置;
    </p>
    <p><img src="@/assets/document/DLinkRouter/2.png" alt="" /></p>
    <p>完成以上设置后,即可在手机Wi-Fi设置界面中分别找到名称为“XXX-2.4G”和“XXX-5G”的Wi-Fi了。</p>
    <p class="warn">
      由于Wi-Fi名称已变更,所有连接该无线路由器的设备都需要重新连网,如果未对密码发生变更则依然为原密码。
    </p>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {}
}
</script>

<style scoped lang="scss">
.page {
  padding: 34px 40px;

  .title {
    font-size: 40px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
  }

  .line {
    width: 100%;
    height: 2px;
    margin: 48px 0;
    border: 2px solid #ececec;
  }

  p {
    margin-bottom: 26px;
    font-size: 28px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
  }

  img {
    width: 100%;
  }

  .warn {
    color: #e0383e;
  }
}
</style>
